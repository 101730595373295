/* eslint-disable */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation, json } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import baseApi from "../../Api/config";

function Theam() {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [THemeList, setTHemeList] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plan = JSON.parse(queryParams.get("plan"));
  const [loader, setLoader] = useState(false);
 const feature=(queryParams.get("feature"));

  useEffect(() => {
    setLoader(true)
    API.Auth.Theme().then((response) => {
      console.log(response?.data?.data_all)
      setLoader(false)
      setTHemeList(response?.data?.data_all);
    });
  }, []);

  const selectTheme = (e, themeId) => {
   API.Auth.SelectThemeById({
      data: {
        theme_id: themeId,
      },
      UserId: UserData?.userdata?._id,

    }).then((response) => {
      console.log("featurefeaturefeature",typeof(feature))
      if(feature === "true"){
      console.log("featurefeaturefeature============",feature)

      navigate(`../${AdminRoute?.Common?.Dashboard}`);
      }
      else{
      navigate(`../${AdminRoute?.Common?.Therapist}`);
      }
    });
  };

  return (
    <div className="plan theme">
      <div className="main-container therapist pb-0">
        <div className="text-center align-middle inner justify-content-center select-theme-mobile-bg-height">
          <div className="row my-auto">
            <h2 className="heading text-center">Select a theme</h2>
            <p className="text-center mb-5">
              Help us serve you better by selecting a plan that suits you best.
            </p>

            {loader ?
              <figure>
                <div className="dot white"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </figure>
              : THemeList?.length === 0 ?
                <div className="alert alert-warning digital-notes-alert" role="alert">
                  Data Not Found
                </div>
                :
                THemeList && THemeList?.map((val, index) => {
                  return (
                    <>
                      {index < plan ?
                        <div
                          className="col-md-4 col-xs-12"
                          key={index}
                          onClick={(e) => selectTheme(e, val?._id)}
                        >
                          <NavLink to={AdminRoute?.Common?.Therapist}>
                            <div className="plan-div mb-4 rounded-4">
                              {/* <div className="light-div pricing-card-heading-free"></div> */}
                              <div
                                className="light-div pricing-card-heading-free mb-1"
                                style={{
                                  backgroundImage: `url(${baseApi?.baseurl}images/${val?.backgroundimagename})`,
                                }}
                              ></div>
                              {/* <h6>Classic Light</h6> */}
                              <h6 className="mb-0">{val?.themeName}</h6>
                            </div>
                          </NavLink>
                        </div>
                        : null}
                    </>
                  );
                })}

            {/* <div className="col-md-4 col-xs-12">
              <NavLink to={AdminRoute?.Common?.Therapist}>
                <div className="plan-div mb-4">
                  <div className="dark-div pricing-card-heading-lite"></div>
                  <h6>Modest Dark</h6>
                </div>
              </NavLink>
            </div>

            <div className="col-md-4 col-xs-12">
              <NavLink to={AdminRoute?.Common?.Therapist}>
                <div className="plan-div mb-4">
                  <div className="gradient-div pricing-card-heading-pro"></div>
                  <h6>Gradient Tone</h6>
                </div>
              </NavLink>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Theam;

import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast } from "react-toastify";
import API from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails"

// Initialize Firebase (do this once at the app level)
const firebaseConfig = {
  apiKey: "AIzaSyAD4kF1AkUEY7VCC1VCl22KdlIppdG0eUI",
  authDomain: "rytlifeclient.firebaseapp.com",
  projectId: "rytlifeclient",
  storageBucket: "rytlifeclient.appspot.com",
  messagingSenderId: "69686032949",
  appId: "1:69686032949:web:e82dccc664f4cbfd68cf7c",
  measurementId: "G-WQ9LDDJ5SN",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const GoogleLogin = () => {
  const navigate = useNavigate();
  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      // await firebase.auth().signInWithPopup(provider);
      // User is signed in, redirect or do something else

      firebase.auth().signInWithPopup(provider)
        .then((result) => {
            // console.log("result", result?.additionalUserInfo?.profile?.email);
            try {
              API.Auth.LoginAPIEmail({
                data: {
                  email: result?.additionalUserInfo?.profile?.email,
                },
              }).then((response) => {
                if (response?.data?.status === 1) {
                  // localStorage.setItem("EmailId", result?.additionalUserInfo?.profile?.email);
                  navigate(`../${AdminRoute?.Auth?.verifyOtpMail}/1?email=${JSON.stringify(result?.additionalUserInfo?.profile?.email)}`);
                } else {
                  toast.error(response?.data?.message);
                }
              });
            } catch (error) {
              console.log(error);
            }


        //   setUid(result.user.uid);
        //   setUserName(result.user.displayName);
        //   navigate("/chats", { replace: true });
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  // const signOut = async () => {
  //     try {
  //         await firebase.auth().signOut();
  //         // User is signed out, update UI accordingly
  //     } catch (error) {
  //         console.error("Error during sign-out:", error);
  //     }
  // };

  return (
    <div>
      <button className="border-0" onClick={signInWithGoogle}><img src="/images/google-logo-icon-png-transparent-background-osteopathy-16.png" height={50}/></button>
      {/* <button onClick={signOut}>Sign out</button> */}
    </div>
  );
};

export default GoogleLogin;


import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
// import { toast } from "react-toastify";


const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = JSON.parse(queryParams.get("email"));
  const mobile_no = JSON.parse(queryParams.get("mobile_no"));

  const initialValues = {
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    PhoneNumber: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (email) {
      setFormData({
        ...formData,
        email: email,
      });
    }
    if (mobile_no) {
      setFormData({
        ...formData,
        PhoneNumber: mobile_no,
      });
    }
  }, [])

  const RegisterValidate = (values) => {
    const errors = {};
    const checkMobile = /^[789]\d{9}$/
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;

    if (!values?.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values?.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values?.email) {
      errors.email = "Email is required";
    } else if (!emailRegrex.test(values?.email)) {
      errors.email = "Enter valid Email";
    }
    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }
    if (!values.PhoneNumber) {
      errors.PhoneNumber = "Phone number is required";
    } else if (!checkMobile.test(values?.PhoneNumber)) {
      errors.PhoneNumber = "Enter valid Phone number";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        API.Auth.Register({
          data: {
            name: formData?.firstName,
            last_name: formData?.lastName,
            dob: formData?.birthDate,
            email: formData?.email,
            phone: formData?.PhoneNumber,
            role: "Client",
          },
        }).then((response) => {
          if (response?.data?.status === 1) {
            // localStorage.setItem("EmailId", response?.data?.data?.email);
            // localStorage.setItem("Mobileno", response?.data?.data?.phone);
            navigate(`../${AdminRoute?.Auth?.VerifyOtp}/2?email=${JSON.stringify(formData?.email)}&mobile_no=${JSON.stringify(formData?.PhoneNumber)}`);
            // toast.success(response?.data?.message);
            setError("");
          }
          else {
            // toast.error(response?.data?.message);
            setError(response?.data?.message);
          }
        })
          .catch((error) => {
            console.log('Error', error.message);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  const disableFutureDate = () => {
    // const today = new Date().toISOString().split('T')[0];
    // return today;
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    return maxDate.toISOString().split('T')[0];
  }

  return (
    <>
      <div className="container">
        <div className="row main-container">
          <div className="col-md-6 col-sm-12 left-text">
            {/* <div className="logo-div">
              <NavLink to="/">
                <img src="/images/logo.png" className="logo" alt="logo" />{" "}
              </NavLink>
            </div> */}
            <h1 className="heading">Begin your transformative journey with our online therapy. </h1>
            {/* <p className="subheading">Mental Health journey</p> */}
            <p>
            Navigate your path to well-being with our dedicated psychologists by your side, every step of the way. 
            </p>
          </div>
          {/* col-md-6 */}
          <div className="col-md-6 col-sm-12">
            <div className="register">
              <h2 className="heading">
                {" "}
                <NavLink to="/">
                  {/* <ArrowLeft className="left-arrow" size={16} /> */}
                  <i className="fa fa-chevron-left left-arrow"></i>
                </NavLink>
                Register
              </h2>
              <form>
                <div>
                  <label><span className="text-danger fw-bold">*</span> First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    autoComplete="off"g
                    required
                  />
                  <p className="text-danger">{formErrors?.firstName}</p>
                </div>
                <div>
                  <label><span className="text-danger fw-bold">*</span> Last Name:</label>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <p className="text-danger">{formErrors?.lastName}</p>
                </div>
                <div>
                  <label><span className="text-danger fw-bold">*</span> Birth Date:</label>
                  <input
                    type="date"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                    autoComplete="off"
                    max={disableFutureDate()}

                    required
                  />
                  <p className="text-danger">{formErrors?.birthDate}</p>
                </div>
                <div>
                  <label><span className="text-danger fw-bold">*</span> E-Mail ID:</label>
                  <input
                    type="email"
                    placeholder="Enter E-Mail ID"
                    name="email"
                    value={formData.email}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                  />
                  <p className="text-danger">{formErrors?.email}</p>
                </div>
                <label><span className="text-danger fw-bold">*</span> Phone Number:</label>
                {/* =================== */}
                {/* <div className="ph-input">
                <PhoneInput
                  // country={"us"}
                  placeholder="Enter Phone Number"
                  onChange={value => setPhoneNumber(value)}
                />
                </div> */}
                {/* ===================== */}

                <input
                  type="text"
                  name="PhoneNumber"
                  value={formData.PhoneNumber}
                  autoComplete="off"
                  placeholder="Enter Mobile Number"
                  onChange={handleChange}
                  required
                />
                <p className="text-danger">{formErrors?.PhoneNumber}</p>

                <Button
                  className="button_common"
                  onClick={(e) => handleSubmit(e)}
                >
                  Verify via OTP
                </Button>
                {error && <p className="text-danger">{error}</p>}
              </form>
            </div>
          </div>
          {/* col-md-6 */}
        </div>
      </div>
      {/* end of container */}
    </>
  );
};

export default Register;

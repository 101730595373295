// App.js
import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import AiTherapist from "../../Assets/Images/ai-therapist.jpg";
import PlayGround from "../../Assets/Images/playground.jpg";
import Groups from "../../Assets/Images/groups.jpg";
import BookSession from "../../Assets/Images/book-session.jpg";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { checkDate, get15MinutesBefore } from "../Common/Functions";
import { FeatrureList } from "../../App";
import JoinSession from "../JoinSession";
import baseApi from "../../Api/config";

function Dashboard() {
  const featureListContext = useContext(FeatrureList);
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [DigitalNotesList, setDigitalNotesList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [CurrentPlan, setCurrentPlan] = useState([]);
  const [moodTraker, setMoodTraker] = useState({
    Joy: "50",
    drive: "50",
    stress: "50",
  });
  const [lifeStyleTraker, setLifeStyleTraker] = useState({
    fodIntake: "50",
    sleep: "50",
    activity: "50",
  });
  const [featurePrice, setFeaturePrice] = useState([]);
  const [currentSession, setCurrentSession] = useState({});
  const [ActivityCount, setActivityCount] = useState(0);
  const [MessagecntCount, setMessagesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [FocussedData, setFocussedData] = useState([]);
  const [joinSessionBtn, setJoinSessionBtn] = useState(false);
  const [therapistData, setTherapishData] = useState({});

  useEffect(() => {
    getDigitalNoteList();
    MyPlanlfeatures();
    FeaturePriceList();
    getCurrentSession();
    getFocussedData();
    TheripistDeatils();
  }, []);

  const TheripistDeatils = () => {
    API.Therapist.getTherpistDetails({
      date: moment(new Date()).format("YYYY-MM-DD"),
    })
      .then((response) => {
        if (response.data.status === 1) {
          setTherapishData(response?.data.data_all[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDigitalNoteList = () => {
    setLoadingList(true);
    try {
      API.Auth.DigitalNotesList().then((response) => {
        if (response.status === 200) {
          setDigitalNotesList(response?.data?.data_all);
          setLoadingList(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  const MyPlanlfeatures = () => {
    setLoading(true);
    try {
      API.Payment.MyPlanlfeatures().then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setCurrentPlan(response.data.data);
          featureListContext.setCurrentPlan(response.data.data);
          if (response.data.welnessdata.length) {
            setMoodTraker({
              ...moodTraker,
              drive: response.data.welnessdata[0].drive,
              Joy: response.data.welnessdata[0].joy,
              stress: response.data.welnessdata[0].stress,
            });
            setLifeStyleTraker({
              ...lifeStyleTraker,
              fodIntake: response.data.welnessdata[0].foodIntake,
              sleep: response.data.welnessdata[0].sleep,
              activity: response.data.welnessdata[0].physicalactivity,
            });
          }
        }
      });
    } catch (error) {
      false(false);
      console.log(error);
    }
  };

  const FeaturePriceList = () => {
    try {
      API.Payment.FeaturePriceList().then((response) => {
        if (response.status === 200) {
          setFeaturePrice(response.data.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlewellness = (val, value) => {
    API.Survey.Wellness({
      data: {
        joy: val === "Joy" ? value : moodTraker.Joy,
        foodIntake: val === "fodIntake" ? value : lifeStyleTraker.fodIntake,
        drive: val === "drive" ? value : moodTraker.drive,
        sleep: val === "sleep" ? value : lifeStyleTraker.sleep,
        stress: val === "stress" ? value : moodTraker.stress,
        physicalactivity: val === "activity" ? value : lifeStyleTraker.activity,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentSession = () => {
    try {
      API.Session.currentSessionList().then((response) => {
        if (response.status === 200) {
          setCurrentSession(response.data.data_all[0]);
          setActivityCount(response.data.activitycount);
          setMessagesCount(response.data.messagecntcount);
          // console.log(response.data.data_all[0].date);
          // console.log(
          //   get15MinutesBefore(response.data.data_all[0].date.slice(0, -1))
          // );

          // Provided time
          const providedTime = moment(
            get15MinutesBefore(response?.data?.data_all[0]?.date?.slice(0, -1))
          );

          // Current time
          const currentTime = moment();
          const endTime = moment(response?.data?.data_all[0]?.timeend);
          // Subtract 15 minutes from the current time
          const fifteenMinutesBefore = currentTime
            .clone()
            .subtract(15, "minutes");
          // Check if provided time is within 15 minutes before current time
          if (currentTime.isBetween(providedTime, endTime)) {
            // console.log(
            //   "Provided time is within 15 minutes before the current time."
            // );
            setJoinSessionBtn(true);
          } else {
            // console.log(
            //   "Provided time is NOT within 15 minutes before the current time."
            // );
            setJoinSessionBtn(false);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = (featureName, fetaure) => {
    if (featurePrice.length) {
      const Feature = featurePrice.find(
        (feature) => feature.feature_name === featureName
      );
      navigate(
        `../${AdminRoute.Common.MakePayment}/${
          fetaure.features
        }/?planID=${JSON.stringify(fetaure._id)}&amount=${JSON.stringify(
          btoa(Feature.feature_price)
        )}`
      );
    }
  };

  const getFocussedData = (info) => {
    API.Auth.Support_API({
      support: "focused",
    })
      .then((response) => {
        if (response.data.status === 1) {
          setFocussedData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      {loading ? (
        <figure>
          <div className="dot white"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </figure>
      ) : (
        <div className="toggleIcon">
          <div className="container-fluid">
            <div className="row list gx-3">
              <div className="navbar-Wrap">
                <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                  <h2 className="heading">
                    Welcome
                    <span className="secheading">
                      {" "}
                      {UserData?.userdata?.name}!
                    </span>
                  </h2>
                  <Header />
                </nav>
              </div>{" "}
              {/* Header*/}
              <div className="col-lg-12 col-xl-9 mb-2">
                <div className="row">
                  {CurrentPlan?.find((item) => item?.features === "Session")
                    ?.is_available !== 1 &&
                  checkDate(
                    new Date(
                      CurrentPlan.find(
                        (item) => item?.features === "Session"
                      )?.endDate
                    )
                  ) ? (
                    <div className="col-lg-12 col-xl-6 mb-2 list-item">
                      <div className="dashboard-inner p-2 h-100">
                        <div className="row h-100">
                          <div className="col-md-6">
                            <div className="time-text-left mr-4 h-100">
                              <div className="dashboard-img-div h-100">
                                <span className="img">
                                  <img src={BookSession} alt="Book A Session" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <div className="time-text-right">
                              <div className="dashboard-text-div">
                                <h3 className="secheading mt-3 mb-2">
                                  Book A Session
                                </h3>
                                <p className="my-2">
                                  Skip the commute, not the support. Book your
                                  online therapy session – your mental gym,
                                  accessible anytime.
                                </p>
                                <Button
                                  className="button_common m-0 mb-2 mt-3"
                                  onClick={() =>
                                    handlePayment(
                                      "Session",
                                      CurrentPlan.find(
                                        (item) => item?.features === "Session"
                                      )
                                    )
                                  }
                                >
                                  <span>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      {" "}
                                      <rect
                                        x="5.73685"
                                        y="12.0526"
                                        width="12.5263"
                                        height="8.94737"
                                        rx="2"
                                        stroke="#333333"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />{" "}
                                      <path
                                        d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
                                        stroke="#333333"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />{" "}
                                    </svg>
                                  </span>
                                  unlock Features
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <JoinSession
                        currentSession={currentSession}
                        joinSessionBtn={joinSessionBtn}
                        therapistData={therapistData}
                      />

                      <div className="col-lg-12 col-xl-6 mb-2 col-xxl-3 list-item">
                        <div className="dashboard-inner p-3">
                          <div className="row h-100">
                            <div className="col-md-12 position-relative pb-4">
                              <div className="time-text-right">
                                <div className="dashboard-text-div">
                                  <h3 className="secheading mb-2">
                                    Updates From Therapist
                                  </h3>
                                  <p className="my-3">
                                    Attend all sessions for speedier recovery.
                                  </p>
                                  {therapistData?.fullname ? (
                                    <>
                                      <div className="update-therapist-box d-flex justfy-content-between justify-content-lg-around align-items-center py-2 mb-1 pt-0">
                                        <div className="name-profile">
                                          <p className="text-center mx-auto d-flex justify-content-center aline-items-center">
                                            <img
                                              src={
                                                therapistData?.image
                                                  ? therapistData?.image
                                                  : AiTherapist
                                              }
                                              alt="PlayGround"
                                            />
                                          </p>
                                          <p className="fw-bold text-center px-2">
                                            {therapistData?.fullname}
                                          </p>
                                        </div>
                                        <div className="activity-messages">
                                          <p className="mt-0 fw-bold d-flex align-items-center">
                                            <span>
                                              {ActivityCount
                                                ? ActivityCount
                                                : "0"}
                                            </span>
                                            Activity
                                          </p>
                                          <p className="mt-0 fw-bold d-flex align-items-center">
                                            <span>
                                              {MessagecntCount
                                                ? MessagecntCount
                                                : "0"}
                                            </span>
                                            Messages
                                          </p>
                                        </div>
                                      </div>

                                      <Button className="button_common m-0 float-end position-absolute bottom-0 dash-button">
                                        <NavLink
                                          to={AdminRoute?.Common?.MyTherapist}
                                        >
                                          View Details
                                        </NavLink>
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <div className="upcoming-session-box d-flex justify-content-between align-items-center px-3 py-2 mb-3">
                                        <div className="hide-session">
                                          <p className="my-2">
                                            Select Therapist First
                                          </p>
                                        </div>
                                      </div>
                                      <Button className="button_common m-0 float-end position-absolute bottom-0 dash-button">
                                        <NavLink
                                          to={`${AdminRoute?.Common?.Therapist}`}
                                        >
                                          Select a Therapist
                                        </NavLink>
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* dashboard 2 sections End*/}
                  <div className="col-lg-12 mb-2 col-xl-12 col-xxl-6 list-item">
                    <div className="dashboard-inner p-3">
                      <div className="row">
                        <div className="dashboard-text-div">
                          <h3 className="secheading">Wellness Tracker</h3>
                          <p className="mb-1">How are you feeling today?</p>
                          <div className="row">
                            <div className="col-md-6 mb-2 wellness-tracker-border">
                              <p className="mb-3 fw-semibold">Mood tracker</p>
                              <h4 className="mb-0 wellness-heading dm-serif-font">
                                Joy
                              </h4>
                              <div className="col-12 wellness-tracker-slider mb-3">
                                <div className="row px-3">
                                  <div className="col-12 slider-input position-relative px-0">
                                    <input
                                      type="range"
                                      className="form-range text-black"
                                      id="typeinp"
                                      min="0"
                                      max="100"
                                      name="answer_score"
                                      value={moodTraker.Joy}
                                      onChange={(e) => [
                                        setMoodTraker({
                                          ...moodTraker,
                                          Joy: e.target.value,
                                        }),
                                        handlewellness("joy", e.target.value),
                                      ]}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                                  <p className=" w-18 text-start progress-bar_text my-0">
                                    Low
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Moderate
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    High
                                  </p>
                                </div>
                              </div>

                              <h4 className="mb-0 wellness-heading dm-serif-font">
                                Drive
                              </h4>
                              <div className="col-12 wellness-tracker-slider mb-3">
                                <div className="row px-3">
                                  <div className="col-12 slider-input position-relative px-0">
                                    <input
                                      type="range"
                                      className="form-range text-black"
                                      id="typeinp"
                                      min="0"
                                      max="100"
                                      name="answer_score"
                                      value={moodTraker.drive}
                                      onChange={(e) => [
                                        setMoodTraker({
                                          ...moodTraker,
                                          drive: e.target.value,
                                        }),
                                        handlewellness("drive", e.target.value),
                                      ]}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                                  <p className=" w-18 text-start progress-bar_text my-0">
                                    Low
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Moderate
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    High
                                  </p>
                                </div>
                              </div>

                              <h4 className="mb-0 wellness-heading dm-serif-font">
                                Stress
                              </h4>
                              <div className="col-12 wellness-tracker-slider">
                                <div className="row px-3">
                                  <div className="col-12 slider-input position-relative px-0">
                                    <input
                                      type="range"
                                      className="form-range text-black"
                                      id="typeinp"
                                      min="0"
                                      max="100"
                                      name="answer_score"
                                      value={moodTraker.stress}
                                      onChange={(e) => [
                                        setMoodTraker({
                                          ...moodTraker,
                                          stress: e.target.value,
                                        }),
                                        handlewellness(
                                          "stress",
                                          e.target.value
                                        ),
                                      ]}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                                  <p className=" w-18 text-start progress-bar_text my-0">
                                    Low
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Moderate
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    High
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-2">
                              <p className="mb-3 fw-semibold">
                                Lifestyle Tracker
                              </p>
                              <h6 className="mb-0 wellness-heading dm-serif-font">
                                Food Intake
                              </h6>
                              <div className="col-12 wellness-tracker-slider mb-3">
                                <div className="row px-3">
                                  <div className="col-12 slider-input position-relative px-0">
                                    <input
                                      type="range"
                                      className="form-range text-black"
                                      id="typeinp"
                                      min="0"
                                      max="100"
                                      name="answer_score"
                                      value={lifeStyleTraker.fodIntake}
                                      onChange={(e) => [
                                        setLifeStyleTraker({
                                          ...lifeStyleTraker,
                                          fodIntake: e.target.value,
                                        }),
                                        handlewellness(
                                          "fodIntake",
                                          e.target.value
                                        ),
                                      ]}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                                  <p className=" w-18 text-start progress-bar_text my-0">
                                    Poor
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Adequate
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Excessive
                                  </p>
                                </div>
                              </div>

                              <h4 className="mb-0 wellness-heading dm-serif-font">
                                Sleep
                              </h4>
                              <div className="col-12 wellness-tracker-slider mb-3">
                                <div className="row px-3">
                                  <div className="col-12 slider-input position-relative px-0">
                                    <input
                                      type="range"
                                      className="form-range text-black"
                                      id="typeinp"
                                      min="0"
                                      max="100"
                                      name="answer_score"
                                      value={lifeStyleTraker.sleep}
                                      onChange={(e) => [
                                        setLifeStyleTraker({
                                          ...lifeStyleTraker,
                                          sleep: e.target.value,
                                        }),
                                        handlewellness("sleep", e.target.value),
                                      ]}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                                  <p className=" w-18 text-start progress-bar_text my-0">
                                    Poor
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Adequate
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Excessive
                                  </p>
                                </div>
                              </div>

                              <h4 className="mb-0 wellness-heading dm-serif-font">
                                Activity
                              </h4>
                              <div className="col-12 wellness-tracker-slider">
                                <div className="row px-3">
                                  <div className="col-12 slider-input position-relative px-0">
                                    <input
                                      type="range"
                                      className="form-range text-black"
                                      id="typeinp"
                                      min="0"
                                      max="100"
                                      name="answer_score"
                                      value={lifeStyleTraker?.activity}
                                      onChange={(e) => [
                                        setLifeStyleTraker({
                                          ...lifeStyleTraker,
                                          activity: e.target.value,
                                        }),
                                        handlewellness(
                                          "activity",
                                          e.target.value
                                        ),
                                      ]}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                                  <p className=" w-18 text-start progress-bar_text my-0">
                                    Poor
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Adequate
                                  </p>
                                  <p className=" w-17 text-center progress-bar_text my-0">
                                    Excessive
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-lg-8">
                    <h3 className="secheading mt-3">Your Support System</h3>
                    <p className="mb-3 text-muted">
                      A friend in need is a friend in deed, and we intend to be
                      that one for you.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-lg-4 mb-2 mb-lg-0">
                    <div className="dashboard-inner p-2 pb-5 h-100 dashboard-inner-boxes">
                      {CurrentPlan?.find(
                        (item) => item?.features === "Therapist"
                      )?.is_available !== 1 &&
                      checkDate(
                        new Date(
                          CurrentPlan?.find(
                            (item) => item?.features === "Therapist"
                          )?.endDate
                        )
                      ) ? (
                        <>
                          <div className="dashboard-img-div">
                            <span className="img">
                              <img src={AiTherapist} alt="PlayGround" />
                            </span>
                          </div>
                          <div className="dashboard-text-div yellow-gradient px-3">
                            <h3 className="secheading mt-3">Ai Therapist</h3>
                            <p className="mb-2">
                              Chat anytime with your psychological companion -
                              instant, affordable insights for your peace of
                              mind.
                            </p>
                            <Button
                              className="button_common m-0 mb-3 position-absolute bottom-0"
                              onClick={() =>
                                handlePayment(
                                  "Aitherapist",
                                  CurrentPlan?.find(
                                    (item) => item?.features === "Therapist"
                                  )
                                )
                              }
                            >
                              <span>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {" "}
                                  <rect
                                    x="5.73685"
                                    y="12.0526"
                                    width="12.5263"
                                    height="8.94737"
                                    rx="2"
                                    stroke="#333333"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />{" "}
                                  <path
                                    d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
                                    stroke="#333333"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />{" "}
                                </svg>
                              </span>
                              unlock features
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className="cursor_pointer">
                          <div className="dashboard-img-div ">
                            <span className="img">
                              <img src={AiTherapist} alt="PlayGround" />
                            </span>
                          </div>
                          <div
                            className="dashboard-text-div yellow-gradient px-3"
                            onClick={() =>
                              navigate(`../${AdminRoute?.Common?.Aitherapist}`)
                            }
                          >
                            <h3 className="secheading mt-3">Ai Therapist</h3>
                            <p>
                              Chat anytime with your psychological companion -
                              instant, affordable insights for your peace of
                              mind.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-4 mb-2 mb-lg-0">
                    {CurrentPlan?.find((item) => item?.features === "Groups")
                      ?.is_available !== 1 &&
                    checkDate(
                      new Date(
                        CurrentPlan?.find(
                          (item) => item?.features === "Groups"
                        )?.endDate
                      )
                    ) ? (
                      <div className="dashboard-inner p-2 pb-5 h-100 dashboard-inner-boxes">
                        <div className="dashboard-img-div">
                          <span className="img">
                            <img src={Groups} alt="AiTherapist" />
                          </span>
                        </div>
                        <div className="dashboard-text-div px-3">
                          <h3 className="secheading mt-3"> Groups</h3>
                          <p className="mb-2">
                            Find your tribe, share challenges, and unlock
                            strength with other driven professionals.
                          </p>
                          <Button
                            className="button_common m-0 mb-3 position-absolute bottom-0"
                            onClick={() =>
                              handlePayment(
                                "Groups",
                                CurrentPlan.find(
                                  (item) => item.features === "Groups"
                                )
                              )
                            }
                          >
                            <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                {" "}
                                <rect
                                  x="5.73685"
                                  y="12.0526"
                                  width="12.5263"
                                  height="8.94737"
                                  rx="2"
                                  stroke="#333333"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />{" "}
                                <path
                                  d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
                                  stroke="#333333"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />{" "}
                              </svg>
                            </span>
                            unlock features
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {CurrentPlan?.find((item) => item.features === "Groups")
                      ?.is_available !== 1 &&
                    checkDate(
                      new Date(
                        CurrentPlan?.find(
                          (item) => item.features === "Groups"
                        )?.endDate
                      )
                    ) ? (
                      <></>
                    ) : (
                      <div
                        className="dashboard-inner p-2 h-100 dashboard-inner-boxes"
                        onClick={() =>
                          navigate(`../${AdminRoute?.Common?.Groupchat}`)
                        }
                      >
                        <div className="dashboard-img-div">
                          <span className="img">
                            <img src={Groups} alt="AiTherapist" />
                          </span>
                        </div>
                        <div className="dashboard-text-div px-3">
                          <h3 className="secheading mt-3"> Groups</h3>
                          <p>
                            Find your tribe, share challenges, and unlock
                            strength with other driven professionals.
                          </p>
                          <div className="row my-2">
                            {FocussedData &&
                              FocussedData?.slice(0, 3).map((slide, i) => (
                                <React.Fragment key={1}>
                                  <div className="col-3">
                                    <span className="group-img position-relative">
                                      {/*  <span className='group-messages position-absolute'></span>*/}
                                      <img
                                        src={
                                          slide.logo
                                            ? `${baseApi.baseurl}/images/${slide?.logo}`
                                            : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`
                                        }
                                        alt="AiTherapist"
                                      />
                                    </span>
                                  </div>
                                </React.Fragment>
                              ))}
                            <div
                              className="col-3 view-all-btn d-flex align-items-center cursor_pointer"
                              onClick={() =>
                                navigate(`../${AdminRoute.Common.Join_Group}`)
                              }
                            >
                              View All
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 col-lg-4">
                    <div className="dashboard-inner p-2 mb-2 h-100 dashboard-inner-boxes">
                      {CurrentPlan.find(
                        (item) => item.features === "Playground"
                      )?.is_available !== 1 &&
                      checkDate(
                        new Date(
                          CurrentPlan.find(
                            (item) => item.features === "Playground"
                          )?.endDate
                        )
                      ) ? (
                        <>
                          <div className="dashboard-img-div">
                            <span className="img">
                              <img src={PlayGround} alt="Groups" />
                            </span>
                          </div>
                          <div className="dashboard-text-div px-3">
                            <h3 className="secheading mt-3">Playground</h3>
                            <p>
                              Unleash your creativity and recharge your mind
                              with playful exercises designed for peak
                              performance.
                            </p>
                            <Button
                              className="button_common m-0 mb-2 mt-3"
                              onClick={() =>
                                handlePayment(
                                  "Plaground",
                                  CurrentPlan.find(
                                    (item) => item.features === "Playground"
                                  )
                                )
                              }
                            >
                              <span>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {" "}
                                  <rect
                                    x="5.73685"
                                    y="12.0526"
                                    width="12.5263"
                                    height="8.94737"
                                    rx="2"
                                    stroke="#333333"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />{" "}
                                  <path
                                    d="M16.4737 8.47368C16.4737 6.00294 14.4707 4 12 4C9.52924 4 7.52631 6.00294 7.52631 8.47368"
                                    stroke="#333333"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />{" "}
                                </svg>
                              </span>
                              unlock features
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div
                          className="cursor_pointer"
                          onClick={() =>
                            navigate(`../${AdminRoute.Common.Playground}`)
                          }
                        >
                          <div className="dashboard-img-div">
                            <span className="img">
                              <img src={PlayGround} alt="Groups" />
                            </span>
                          </div>
                          <div className="dashboard-text-div px-3">
                            <h3 className="secheading mt-3">Playground</h3>
                            <p>
                              Unleash your creativity and recharge your mind
                              with playful exercises designed for peak
                              performance.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* col-lg-9 */}
              <div className="col-lg-12 col-xl-3 mb-2 list-item">
                <div className="dashboard-inner p-3 mb-2 h-100">
                  <h3 className="secheading mb-3">Digital Notes</h3>
                  {/* <div className="dashboard-img-div h-50">
                  <span className="img">
                  <img src={DigitalNotes} alt="DigitalNotes" />
                  </span>
                </div> */}
                  {CurrentPlan.find((item) => item.features === "Notes")
                    ?.is_available !== 1 &&
                  checkDate(
                    new Date(
                      CurrentPlan.find(
                        (item) => item.features === "Notes"
                      )?.endDate
                    )
                  ) ? (
                    <div className="dashboard-text-div">
                      <p className="m-2 mx-0">
                        Track your progress and master your mental fitness with
                        a personalized journaling tool and mood tracker.{" "}
                      </p>
                      <h3 className="secheading my-4 text-center">
                        “Journaling is wonderful pathway is self awareness’
                      </h3>
                      <div className="text-center ">
                        <Button
                          type="submit"
                          className="button_common mt-3 m-auto"
                          onClick={() =>
                            handlePayment(
                              "Digitalnotes",
                              CurrentPlan.find(
                                (item) => item.features === "Notes"
                              )
                            )
                          }
                        >
                          <span>
                            <svg
                              className="w-8 h-8 xl:w-5 xl:h-5"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M21.266 20.998H2.73301C2.37575 20.998 2.04563 20.8074 1.867 20.498C1.68837 20.1886 1.68838 19.8074 1.86701 19.498L11.133 3.49799C11.3118 3.1891 11.6416 2.9989 11.9985 2.9989C12.3554 2.9989 12.6852 3.1891 12.864 3.49799L22.13 19.498C22.3085 19.8072 22.3086 20.1882 22.1303 20.4975C21.9519 20.8069 21.6221 20.9976 21.265 20.998H21.266ZM12 5.99799L4.46901 18.998H19.533L12 5.99799ZM12.995 14.999H10.995V9.99799H12.995V14.999Z"></path>
                              <path d="M11 16H13V18H11V16Z"></path>
                            </svg>
                          </span>
                          unlock features
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row px-2 digital-notes-wrap">
                        <div className="col-12 px-0">
                          {loadingList ? (
                            <div className="container_loader"></div>
                          ) : DigitalNotesList.length === 0 ? (
                            <div className="alert alert-warning" role="alert">
                              Data Not Found
                            </div>
                          ) : (
                            DigitalNotesList &&
                            DigitalNotesList.map((val) => {
                              return (
                                <div className="digital-notes px-2 py-3 mt-2 position-relative">
                                  {val?.response &&
                                  val?.response?.responce &&
                                  val?.is_seen === 0 ? (
                                    <p className="notes-count position-absolute">
                                      1
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                  <h6>{val?.title}</h6>
                                  <p className="text-grey">
                                    {moment(val?.createdAt).format("LL")}
                                  </p>
                                  <p className="mb-0">
                                    {val?.description.length > 40
                                      ? `${val?.description.substring(
                                          0,
                                          40
                                        )}....`
                                      : val?.description}
                                  </p>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                      <div className="add-notes-btn position-absolute">
                        <NavLink to={AdminRoute.Common.DigitalNotes}>
                          <p className="text-white fw-bold h2 text-center">+</p>
                        </NavLink>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* col-lg- */}
            </div>

            <Footer />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;

/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import API from "../../Api/Api";
import baseApi from "../../Api/config";
import moment from "moment";
import { formatDateTime, getInitialsLetters } from "../Common/Functions";
import AdminRoute from "../../Route/RouteDetails";
import {
  get15MinutesBefore,
  get1HourFuture,
  combineDateTime,
} from "../Common/Functions";
import { toast } from "react-toastify";
import { socket } from "../../socket";

const Groupchat = () => {
  const [Focussed, setFocussed] = useState([]);
  const [Support, setSupport] = useState([]);
  const [tab, setTab] = useState("Focussed");
  const [room] = useState("chatmessage");
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [loadingChat, setLoadingChat] = useState(false);
  const [rows, setRows] = useState(1);
  const [allResourceData, setAllResourceData] = useState([]);
  const [allActivityData, setAllActivityData] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [loadingSubmitAns, setLoadingSubmitAns] = useState(false);
  const chatHistoryRef = useRef(null);

  const [loading, setLoading] = useState({ Focus: false, support: false });

  const navigate = useNavigate();

  const renderActionButton = (data) => {
    API.Session.JoinMeeting({
      data: {
        role: "Client",
        meetingId: data?.mettingname,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          navigate(
            `../${AdminRoute.Common.VideoCall}?id=${btoa(
              data?.meetingtoken
            )}&name=${data?.mettingname}`
          );
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get all groups info
  useEffect(() => {
    getGroupList_focused();
    getGroupList_support();
  }, []);
  //API call for Focused
  const getGroupList_focused = (text) => {
    setLoading({ ...loading, Focus: true });
    try {
      API.Auth.Focussed_API({
        focused: "focused",
        serchKey: text,
        ingroup: "yes",
      }).then((response) => {
        if (response.data.status === 1) {
          // console.log(response?.data?.data_all[0])
          setLoading({ ...loading, Focus: false });
          setSelectedGroup(response?.data?.data_all[0]);
          HandleMsgCount(response?.data?.data_all[0]);
          setFocussed(response?.data?.data_all);
          getGropAllChats(response?.data?.data_all[0]);
          getAllEventData(response?.data?.data_all[0]);
          getGropuMedia(response?.data?.data_all[0]);
        }
      });
    } catch (error) {
      setLoading({ ...loading, Focus: false });
      console.log(error);
    }
  };

  //API call for Support
  const getGroupList_support = (call, text) => {
    setLoading({ ...loading, support: true });
    try {
      API.Auth.Support_API({
        support: "support",
        serchKey: text,
        ingroup: "yes",
      }).then((response) => {
        if (response.data.status === 1) {
          if (call === "call") {
            setLoading({ ...loading, support: false });
            setSelectedGroup(response?.data?.data_all[0]);
            HandleMsgCount(response?.data?.data_all[0]);
            getGropAllChats(response?.data?.data_all[0]);
            getAllEventData(response?.data?.data_all[0]);
            getGropuMedia(response?.data?.data_all[0]);
          }
          setSupport(response?.data?.data_all);
        }
      });
    } catch (error) {
      setLoading({ ...loading, support: false });
      console.log(error);
    }
  };

  const getAllEventData = (val) => {
    API.Groups.EventList({
      group_id: val?._id,
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          setAllActivityData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        setLoadingChat(false);
      });
  };
  const getGropuMedia = (val) => {
    API.Groups.Medialist({
      group_id: val?._id,
    })
      .then((response) => {
        if (response.data.status === 1) {
          setAllResourceData(response.data.data_all);
        }
      })
      .catch((error) => {
        setLoadingChat(false);
      });
  };

  const sendMessage = async () => {
    if (currMessage !== "") {
      const messageData = {
        room_name: room,
        author: `${UserData?.userdata?.name} ${UserData?.userdata?.last_name}`,
        user_id: UserData?.userdata?._id,
        group_id: selectedGroup?._id,
        message: currMessage,
        time: new Date(),
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
  };

  useEffect(() => {
    if (!socket || !room) return;

    const handleData = (data) => {
      if (selectedGroup && selectedGroup._id === data.group_id) {
        setMessageList((list) => [...list, data]);
      }
    };

    socket.on(room, handleData);

    return () => {
      socket.off(room, handleData);
    };
  }, [socket, room, selectedGroup]);

  const getGroup_info = (e, val) => {
    setMessageList([]);
    setSelectedGroup(val);
    HandleMsgCount(val);
    getGropAllChats(val);
    getAllEventData(val);
    getGropuMedia(val);
  };
  const getGropAllChats = (val) => {
    setLoadingChat(true);
    API.Groups.GroupsChatList({
      userId: UserData?.userdata?._id,
      group_id: val?._id,
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          setMessageList(response?.data?.data_all);
          setLoadingChat(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingChat(false);
      });
  };
  const handleSelect = (key) => {
    if (key === "Focussed") {
      getGroupList_focused();
      setTab(key);
    }
    if (key === "Support") {
      getGroupList_support("call");
      setTab(key);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setRows(1);
      sendMessage(e);
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        currMessage.substring(0, cursorPosition) +
        "\n" +
        currMessage.substring(cursorPosition);
      setCurrMessage(newInputValue);
      e.preventDefault();
    }
  };
  const handleSearchText = (text) => {
    if (tab === "Focussed") {
      getGroupList_focused(text);
    }
    if (tab === "Support") {
      getGroupList_support("", text);
    }
  };
  // const handleDownload = async (imageUrl) => {
  //    console.log(imageUrl)
  //    let extension = imageUrl.split('.').pop()
  //    const response = await fetch(imageUrl);
  //    const blob = await response.blob();
  //    const url = window.URL.createObjectURL(new Blob([blob]));
  //    const link = document.createElement('a');
  //    link.href = url;
  //    link.setAttribute('download', `image.${extension}`);
  //    document.body.appendChild(link);
  //    link.click();
  //    document.body.removeChild(link);
  // };

  const handleSubmitAns = (val, option) => {
    if (!loadingSubmitAns) {
      setLoadingSubmitAns(true);
      const requestData = {
        event_id: val?._id,
        created_by: UserData?.userdata?._id,
      };
      requestData[option] = true;
      API.Groups.AnswerEvent({
        data: requestData,
      })
        .then((response) => {
          if (response?.data?.status === 1) {
            setLoadingSubmitAns(false);
            getAllEventData(val?.group[0]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingSubmitAns(false);
        });
    }
  };

  const HandleMsgCount = (val) => {
    API.Groups.Groupcount({
      data: {
        groupid: val?._id && val?._id,
        count: val?.msgunread ? val?.msgunread : 0,
      },
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          setLoadingSubmitAns(false);
          getAllEventData(val?.group[0]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubmitAns(false);
      });
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messageList]);

  //jsx
  return (
    <div className="d-flex h-dashboard conversation-page list">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row">
            <div className="nav-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1">My Groups</h2>
                  <Button
                    type="submit"
                    className="create-new_notes button_common notes-btn transparent-btn"
                  >
                    <NavLink to={AdminRoute?.Common?.Join_Group}>
                      Join Groups{" "}
                    </NavLink>
                  </Button>
                </div>
                <Header />
              </nav>
            </div>
            <div className="col-12">
              <Button
                type="submit"
                className="create-new_notes button_common notes-btn transparent-btn d-block d-md-none mb-2 mt-1 me-0"
              >
                <NavLink to={AdminRoute?.Common?.Join_Group}>
                  Join Groups{" "}
                </NavLink>
              </Button>
            </div>
            <p className="mb-3 gray-text">
              Here is list of all your focused and support groups.
            </p>
            <div className="col-lg-3 col-md-5 col-sm-5 mb-2 list-item">
              <div className="dashboard-inner chat-left-div bg-pink shadow-pink">
                <div className="sessions-tabs chat-tabs mt-2">
                  <Tabs
                    defaultActiveKey="Focussed"
                    id="uncontrolled-tab-example"
                    className="mb-3 p-0 flex-nowrap"
                    onSelect={handleSelect}
                  >
                    <Tab eventKey="Focussed" title="Focused">
                      <div className="p-0 clearfix row sessions-text">
                        <div className="people-list" id="people-list">
                          <div className="search position-relative mb-3">
                            <input
                              type="text"
                              placeholder="search"
                              onChange={(e) => handleSearchText(e.target.value)}
                            />
                            <i className="fa fa-search position-absolute"></i>
                          </div>
                          <ul className="people-members-list grouplist-v-height">
                            {loading.Focus ? (
                              <span> Loading...</span>
                            ) : Focussed.length === 0 ? (
                              <span> Data not found ...</span>
                            ) : (
                              Focussed?.map((val) => (
                                <li
                                  className="clearfix"
                                  key={val._id}
                                  onClick={(e) => getGroup_info(e, val)}
                                >
                                  <img
                                    className="icon_group"
                                    src={`${baseApi.baseurl}/images/${val.logo}`}
                                    alt="avatar"
                                  />
                                  <div className="about">
                                    <div className="name fw-bold">
                                      {val?.name}
                                    </div>
                                    <span className="members">
                                      Members: {val?.currentmember}/
                                      {val?.members}
                                    </span>
                                  </div>
                                  <div className="status">
                                    <div className="day">
                                      {val?.currentdata &&
                                        formatDateTime(val?.currentdata)}
                                    </div>
                                    {val?.msgunread !== 0 &&
                                    val?.msgunread >= 0 ? (
                                      <span className="circle">
                                        {val?.msgunread}
                                      </span>
                                    ) : null}
                                  </div>
                                </li>
                              ))
                            )}
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    {/* end Focussed-tab */}
                    <Tab eventKey="Support" title="Support">
                      <div className="row sessions-text p-0 clearfix">
                        <div className="people-list" id="people-list">
                          <div className="search position-relative mb-3">
                            <input
                              type="text"
                              placeholder="search"
                              onChange={(e) => handleSearchText(e.target.value)}
                            />
                            <i className="fa fa-search position-absolute"></i>
                          </div>
                          <ul className="people-members-list grouplist-v-height">
                            {loading.Focus ? (
                              <span> Loading...</span>
                            ) : Support.length === 0 ? (
                              <span> Data not found ... </span>
                            ) : (
                              Support?.map((val) => (
                                <li
                                  className="clearfix"
                                  key={val._id}
                                  onClick={(e) => getGroup_info(e, val)}
                                >
                                  <img
                                    className="icon_group"
                                    src={`${baseApi.baseurl}/images/${val.logo}`}
                                    alt="avatar"
                                  />
                                  <div className="about">
                                    <div className="name">{val?.name}</div>
                                    {/* <span className="members">
                                      Members: {val?.currentmember}/
                                      {val?.members}
                                    </span> */}
                                  </div>
                                  <div className="status">
                                    <div className="day">
                                      {val?.currentdata &&
                                        formatDateTime(val?.currentdata)}
                                    </div>
                                    {val?.msgunread !== 0 &&
                                    val?.msgunread >= 0 ? (
                                      <span className="circle">
                                        {val?.msgunread}
                                      </span>
                                    ) : null}
                                  </div>
                                </li>
                              ))
                            )}
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    {/* end Support-tab */}
                  </Tabs>
                </div>
              </div>
            </div>
            {/* end col-lg-3  */}
            <div className="col-lg-9 col-md-7 col-sm-7 mb-2 list-item">
              {selectedGroup && Object.keys(selectedGroup).length > 0 ? (
                <div className="dashboard-inner">
                  <div className="chat">
                    <div className="chat-header bg-pink clearfix rounded-pill">
                      <img
                        className="icon_group"
                        src={`${baseApi.baseurl}/images/${selectedGroup?.logo}`}
                        alt="avatar"
                      />
                      <div className="chat-about">
                        <div className="chat-with secheading">
                          {selectedGroup?.name}
                        </div>
                        {selectedGroup?.type === "focused" ? (
                          <div className="chat-num-messages">
                            This group expires on{" "}
                            {moment(selectedGroup?.enddate).format(
                              "Do MMMM YYYY"
                            )}
                          </div>
                        ) : null}
                      </div>
                      {/*
                                 <div className="button">
                                 <Button type="submit" className="button_common m-0 p-2">
                                    <div className="button"><Button type="submit" onClick={() => renderActionButton()} className="button_common m-0 p-2">Join Group Session</Button></div>
                                 </Button>
                              </div>
                              */}
                    </div>
                  </div>
                  <div className="col-md-12  mb-2 list-item">
                    <div className="sessions-tabs">
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey="home" title="Discussion">
                          <div className="d-flex justify-content-around">
                            <div className="chat">
                              <div
                                className="chat-history chat-history-group"
                                ref={chatHistoryRef}
                              >
                                {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                                <ul>
                                  {/*
                                          <li className="receive-message">
                                             <div className="message my-message">
                                                <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                   </span><span className="ml-piv">Uma Joshi</span>
                                                </h6>
                                                <p>Lorem ipsum dolor sit exercitation ullamco</p>
                                                <div className="message-data-time message-data align-right">10:10 AM, Today</div>
                                             </div>
                                          </li>
                                          <li className="send-message">
                                             <div className="message my-message">
                                                <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                   </span><span className="ml-piv">Uma Joshi</span>
                                                </h6>
                                                <p>Lorem ipsum dolor sit exercitation ullamco</p>
                                                <div className="message-data-time message-data align-right">10:10 AM, Today</div>
                                             </div>
                                          </li>
                                          */}
                                  {messageList &&
                                    messageList?.map((messageContent, i) => {
                                      return (
                                        <li
                                          key={messageContent?._id}
                                          className={
                                            UserData?.userdata?._id ===
                                            messageContent.user_id
                                              ? "send-message "
                                              : "receive-message"
                                          }
                                        >
                                          <div className="message other-message">
                                            <h6 className="d-flex align-items-center">
                                              <p className="user-icon-avatar rounded-circle text-center mb-0 me-2 text-white">
                                                {messageContent?.author
                                                  ? getInitialsLetters(
                                                      messageContent?.author?.split(
                                                        " "
                                                      )[0],
                                                      messageContent?.author?.split(
                                                        " "
                                                      )[1]
                                                    )?.toUpperCase()
                                                  : messageContent?.user[0]
                                                      ?.fullname
                                                  ? getInitialsLetters(
                                                      messageContent?.user[0]?.fullname?.split(
                                                        " "
                                                      )[0],
                                                      messageContent?.user[0]?.fullname?.split(
                                                        " "
                                                      )[1]
                                                    )?.toUpperCase()
                                                  : getInitialsLetters(
                                                      messageContent?.user[0]
                                                        ?.name,
                                                      messageContent?.user[0]
                                                        ?.last_name
                                                    )?.toUpperCase()}
                                              </p>
                                              <span className="ml-piv">
                                                {messageContent?.author
                                                  ? messageContent?.author
                                                  : messageContent?.user[0]
                                                      ?.fullname
                                                  ? messageContent?.user[0]
                                                      ?.fullname
                                                  : `${messageContent?.user[0]?.name} ${messageContent?.user[0]?.last_name}`}
                                              </span>
                                            </h6>
                                            <p>{messageContent.message}</p>
                                            <div className="message-data-time message-data align-right">
                                              {messageContent?.createdAt
                                                ? formatDateTime(
                                                    messageContent?.createdAt
                                                  )
                                                : formatDateTime(new Date())}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                              {/* end chat-history */}
                              <div className="send-box">
                                <textarea
                                  // type="text"
                                  className="form-control bg-pink rounded-pill me-2"
                                  placeholder="Write a message..."
                                  tabIndex="0"
                                  rows={rows}
                                  cols={50}
                                  // autoComplete="off"
                                  value={currMessage}
                                  onChange={(event) =>
                                    setCurrMessage(event.target.value)
                                  }
                                  onKeyDown={handleKeyPress}
                                />
                                <div className="send-box-icon">
                                  <Button
                                    className="btn btn--send"
                                    title="Send message"
                                    tabIndex="0"
                                    onClick={sendMessage}
                                  >
                                    <svg
                                      className="btn__icon"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <line
                                        x1="22"
                                        y1="2"
                                        x2="11"
                                        y2="13"
                                      ></line>
                                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                    </svg>
                                  </Button>
                                </div>
                              </div>{" "}
                              {/* end chat-message */}
                            </div>
                          </div>
                        </Tab>
                        {/* end Discussion-tab */}
                        <Tab eventKey="activity" title="Activity">
                          <div className="d-flex flex-end">
                            <div className="chat">
                              <div className="chat-history">
                                {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                                <ul>
                                  {allActivityData.length === 0 ? (
                                    <span>Data not found </span>
                                  ) : (
                                    allActivityData?.map((val) => {
                                      const date =
                                        val?.data?.startDate &&
                                        val?.data?.startTime
                                          ? combineDateTime(
                                              val?.data?.startDate,
                                              val?.data?.startTime
                                            )
                                          : new Date(); // shedual time zone
                                      const currdate = new Date();
                                      const beforeminute = date
                                        ? new Date(get15MinutesBefore(date))
                                        : new Date(); // get 15 min before time
                                      const endDate =
                                        date && new Date(get1HourFuture(date));
                                      const startDateResult =
                                        currdate > beforeminute; // true so sesstion btn show
                                      const endDateResult = currdate < endDate; // true seesion btn show

                                      // Provided time (15 minutes before the given date)
                                      const providedTime = moment(
                                        get15MinutesBefore(date)
                                      ).toISOString();

                                      // Given ISO 8601 date and time
                                      const isoDate = val?.data?.startDate;
                                      const isoTime = val?.data?.startTime;

                                      // Combine the date and time
                                      const combinedDateTime = `${
                                        isoDate?.split("T")[0]
                                      } ${isoTime}`;

                                      // Convert using moment.js
                                      const formattedStartDateTime =
                                        moment(combinedDateTime);

                                      // Given ISO 8601 date and time
                                      const isoEndDate = val?.data?.endDate;
                                      const isoEndTime = val?.data?.endTime;

                                      // Combine the date and time
                                      const combinedEndDateTime = `${
                                        isoEndDate?.split("T")[0]
                                      } ${isoEndTime}`;

                                      // Convert using moment.js
                                      const formattedEndDateTime =
                                        moment(combinedEndDateTime);

                                      // Current time
                                      const currentTime = moment();

                                      // End time
                                      const endTime = moment(endDate);

                                      // console.log(
                                      //   "currentTime",
                                      //   val?.data?.type,
                                      //   currentTime
                                      // );
                                      // console.log(
                                      //   "providedTime",
                                      //   val?.data?.type,
                                      //   formattedStartDateTime
                                      // );
                                      // console.log(
                                      //   "endTime",
                                      //   val?.data?.type,
                                      //   formattedEndDateTime
                                      // );

                                      // Convert the ISO strings back to Moment objects for comparison
                                      const currentTimeMoment =
                                        moment(currentTime);
                                      const providedTimeMoment =
                                        moment(providedTime);
                                      const endTimeMoment = moment(endTime);

                                      // Check if the current time is between the provided time and end time
                                      const isBetween =
                                        currentTimeMoment.isBetween(
                                          formattedStartDateTime,
                                          formattedEndDateTime
                                        );

                                      return (
                                        <React.Fragment key={val.data._id}>
                                          {val.data.type?.toLowerCase() ===
                                            "poll" ||
                                          val.data.type?.toLowerCase() ===
                                            "quiz" ? (
                                            <li className="clearfix">
                                              <div className="message other-message">
                                                <div className="activity-width">
                                                  <h6>
                                                    <span className="user-icon-avatar rounded-circle text-center mb-0 me-2 text-white">
                                                      {getInitialsLetters(
                                                        val?.data?.user[0]?.fullname?.split(
                                                          " "
                                                        )[0],
                                                        val?.data?.user[0]?.fullname?.split(
                                                          " "
                                                        )[1]
                                                      )}
                                                    </span>
                                                    <span className="ml-piv">
                                                      {
                                                        val?.data?.user[0]
                                                          ?.fullname
                                                      }
                                                    </span>
                                                  </h6>
                                                  <h4>{val.data?.question}</h4>
                                                  <p>{val.data?.description}</p>
                                                  <div className="message-data-time message-data">
                                                    {moment(
                                                      val.data?.createdAt
                                                    ).format("LL")}
                                                  </div>

                                                  {val?.isresSubmited ? (
                                                    <>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          style={{
                                                            width: `${
                                                              ((val?.score
                                                                ?.option1 ||
                                                                0) /
                                                                (val
                                                                  ?.totaloption
                                                                  ?.total ||
                                                                  1)) *
                                                              100
                                                            }%`,
                                                          }}
                                                          aria-valuenow="25"
                                                          aria-valuemin="0"
                                                          aria-valuemax="100"
                                                        >
                                                          <p className="ps-3">
                                                            Answer
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option1) /
                                                                val?.totaloption
                                                                  ?.total
                                                            )}
                                                            %
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          style={{
                                                            width: `${
                                                              ((val?.score
                                                                ?.option2 ||
                                                                0) /
                                                                (val
                                                                  ?.totaloption
                                                                  ?.total ||
                                                                  1)) *
                                                              100
                                                            }%`,
                                                          }}
                                                          aria-valuenow="25"
                                                          aria-valuemin="0"
                                                          aria-valuemax="100"
                                                        >
                                                          <p className="ps-3">
                                                            Answer
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option2) /
                                                                val?.totaloption
                                                                  ?.total
                                                            )}
                                                            %
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          style={{
                                                            width: `${
                                                              ((val?.score
                                                                ?.option3 ||
                                                                0) /
                                                                (val
                                                                  ?.totaloption
                                                                  ?.total ||
                                                                  1)) *
                                                              100
                                                            }%`,
                                                          }}
                                                          aria-valuenow="25"
                                                          aria-valuemin="0"
                                                          aria-valuemax="100"
                                                        >
                                                          <p className="ps-3">
                                                            Answer
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option3) /
                                                                val?.totaloption
                                                                  ?.total
                                                            )}
                                                            %
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div
                                                        className="chat-header shadow-pink mb-3 clearfix cursor_pointer"
                                                        onClick={() =>
                                                          handleSubmitAns(
                                                            val.data,
                                                            "option1"
                                                          )
                                                        }
                                                      >
                                                        {val.data?.option1}
                                                      </div>
                                                      <div
                                                        className="chat-header shadow-pink clearfix cursor_pointer"
                                                        onClick={() =>
                                                          handleSubmitAns(
                                                            val.data,
                                                            "option2"
                                                          )
                                                        }
                                                      >
                                                        {val.data?.option2}
                                                      </div>
                                                      <div
                                                        className="chat-header shadow-pink clearfix cursor_pointer"
                                                        onClick={() =>
                                                          handleSubmitAns(
                                                            val.data,
                                                            "option3"
                                                          )
                                                        }
                                                      >
                                                        {val.data?.option3}
                                                      </div>
                                                    </>
                                                  )}
                                                  <div className="mb-3 mt-3 note-msg-text">
                                                    {/* <span className="message-data-time message-data  align-left">
                                                      Note: You can only vote
                                                      until 20-7-2023
                                                    </span> */}
                                                    {/* <span className="message-data-time message-data align-right">
                                                      {moment(
                                                        val.data?.startDate
                                                      ).format("LL")}{" "}
                                                      {val.data?.startTime}
                                                    </span> */}
                                                  </div>
                                                  <div className="d-flex justify-content-between time-lbl text-dark mx-2 my-1">
                                                    {val?.isresSubmited &&
                                                    val.data.type?.toLowerCase() ===
                                                      "quiz" &&
                                                    val.data.answer ? (
                                                      <div>
                                                        <label>Answer : </label>
                                                        <label>
                                                          {" "}
                                                          &emsp;
                                                          {val?.isresSubmited &&
                                                            val?.data?.answer}
                                                        </label>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ) : (
                                            <>
                                              {val?.data?.type?.toLowerCase() ===
                                              "event" ? (
                                                <li>
                                                  <div className="message other-message overflow-hidden event-box">
                                                    {isBetween ? (
                                                      <div className="button mb-3">
                                                        <Button
                                                          type="submit"
                                                          className="button_common m-0 p-2"
                                                        >
                                                          <div className="button">
                                                            <Button
                                                              type="submit"
                                                              onClick={() =>
                                                                renderActionButton(
                                                                  val?.data
                                                                )
                                                              }
                                                              className="button_common m-0 p-2"
                                                            >
                                                              Join Group Session
                                                            </Button>
                                                          </div>
                                                        </Button>
                                                      </div>
                                                    ) : null}
                                                    <h4>
                                                      Event Title :{" "}
                                                      {val?.data?.question}{" "}
                                                    </h4>
                                                    <div className="mb-2">
                                                      Description :{" "}
                                                      {val?.data?.description}{" "}
                                                    </div>
                                                    <div className="message-data-time p-0">
                                                      Date :{" "}
                                                      {moment(
                                                        val?.data?.startDate
                                                      ).format("LL")}
                                                    </div>
                                                    <div className="message-data-time p-0">
                                                      Start Time :{" "}
                                                      {val?.data?.startTime}
                                                    </div>
                                                    <div className="message-data-time p-0">
                                                      End Time :{" "}
                                                      {val?.data?.endTime}
                                                    </div>
                                                  </div>
                                                </li>
                                              ) : null}
                                            </>
                                          )}
                                        </React.Fragment>
                                      );
                                    })
                                  )}
                                  {/* 
                                          <li>
                                             <div className="message my-message">
                                                <div className="activity-width">
                                                   <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                      </span><span className="ml-piv">Discussion Joshi</span>
                                                   </h6>
                                                   <h4>Which of the following method do you prefer?</h4>
                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eius tempor incididunt ut labore et dolore magna aliqua</p>
                                                   <div className="message-data-time message-data">Started 12-Jun-23 10:00 am</div>
                                                   <div className="chat-header shadow-pink mb-3 bg-white clearfix">Journal Writing</div>
                                                   <div className="chat-header shadow-pink bg-white clearfix">One on One Discussion</div>
                                                   <div className='mb-3 mt-3 note-msg-text'>
                                                      <span className="message-data-time message-data  align-left">Note: You can only vote until 20-7-2023</span>
                                                      <span className="message-data-time message-data align-right">Started 12-Jun-23 10:00 am</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </li>
                                          */}
                                </ul>
                              </div>
                              {/* end chat-history */}
                              {/*
                                          <div className="send-box">
                                                <input
                                                   type="text"
                                                   className="send-box-input bg-pink"
                                                   placeholder="Write a message..."
                                                   tabIndex="0" autoComplete="off" />
                                                <div className="send-box-icon send-box-icon-orange">
                                                   <Button className="btn btn--send" title="Send message" tabIndex="0">
                                                      <svg className="btn__icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                         <line x1="22" y1="2" x2="11" y2="13"></line>
                                                         <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                      </svg>
                                                   </Button>
                                                </div>
                                             </div>
                                       */}
                              {/* end chat-message */}
                            </div>
                          </div>
                        </Tab>
                        {/* end Activity-tab */}
                        <Tab
                          eventKey="resource"
                          title="Resource"
                          className="tab-content"
                        >
                          <div className="d-flex chat justify-content-around resource">
                            <div className="chat">
                              <div className="chat-history">
                                {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                                <div className="row row-flex row-flex-wrap px-4">
                                  {allResourceData.length === 0 ? (
                                    <span>Data not found </span>
                                  ) : (
                                    allResourceData?.map((val) => (
                                      <div
                                        className="col-xl-3 col-lg-6 col-md-6 mb-3"
                                        key={val._id}
                                      >
                                        <a
                                          href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <div className="panel panel-default flex-col h-100">
                                            <div className="drop_box">
                                              <div
                                                className="d-flex pdf-div cursor_pointer"
                                                // onClick={() => handleDownload(val?.mediafile)}
                                              >
                                                <span
                                                  className={
                                                    val?.type === "pdf"
                                                      ? "pdf-icon"
                                                      : "media-icon"
                                                  }
                                                ></span>
                                                <span className="pdf-text">
                                                  {" "}
                                                  Download
                                                </span>
                                              </div>

                                              {/* {val?.type === "pdf" ? (
                                                <h6>
                                                  {val?.mediafile?.substr(34)}
                                                </h6>
                                              ) : (
                                                // <h6>{val?.mediafile}</h6>
                                              )} */}
                                              <p>Media Type: {val?.type}</p>
                                              <p>{val?.originalfilename}</p>

                                              <p>
                                                {moment(val?.createdAt).format(
                                                  "LL"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                              {/* end chat-history */}
                              {/*
                                            <div className="send-box">
                                            <input
                                               type="text"
                                               className="send-box-input bg-pink"
                                               placeholder="Write a message..."
                                               tabIndex="0"
                                               autoComplete="off"
                                            />
                                            <div className="send-box-icon send-box-icon-orange">
                                               <Button
                                                  className="btn btn--send"
                                                  title="Send message"
                                                  tabIndex="0"
                                               >
                                                  <svg
                                                     className="btn__icon"
                                                     viewBox="0 0 24 24"
                                                     fill="none"
                                                     stroke="currentColor"
                                                     strokeWidth="2"
                                                     strokeLinecap="round"
                                                     strokeLinejoin="round"
                                                  >
                                                     <line x1="22" y1="2" x2="11" y2="13"></line>
                                                     <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                  </svg>
                                               </Button>
                                            </div>
                                         </div>
                                          */}{" "}
                              {/* end chat-message */}
                            </div>
                          </div>
                        </Tab>
                        {/* end Resource-tab */}
                      </Tabs>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="dashboard-inner">
                  <div className="chat">
                    <div className="chat-header bg-pink clearfix">
                      <div className="chat-about">
                        <div className="chat-with secheading text-danger">
                          No group selected ...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* end col-lg-9 */}
          </div>
          <Footer />
        </div>
        {/* end conversation-page */}
      </div>
    </div>
  );
};
export default Groupchat;

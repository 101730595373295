import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import moment from 'moment';
import { useNavigate } from "react-router-dom";


const MySession = () => {
  const navigate = useNavigate();
  const [sumarySessionList, setSumarySessionList] = useState([]);
  const [upcomingSessionList, setUpcomingSessionList] = useState([]);
  const [loadSumaryList, setLoadSumaryList] = useState(false);
  const [loadUpcomingList, setLoadUpcomingList] = useState(false);


  useEffect(() => {
    getSumarySessionList()
    getUpcomingSessionList()
  }, [])

  // get summary list
  const getSumarySessionList = () => {
    setLoadSumaryList(true)
    try {
      API.Session.sumarySessionList().then((response) => {
        if (response.status === 200) {
          setSumarySessionList(response?.data?.data_all);
          setLoadSumaryList(false)
        }
      });
    } catch (error) {
      console.log(error);
      setLoadSumaryList(false)
    }
  }

  const getUpcomingSessionList = () => {
    setLoadUpcomingList(true)
    try {
      API.Session.upcomingSessionList().then((response) => {
        if (response.status === 200) {
          setUpcomingSessionList(response?.data?.data_all);
          setLoadUpcomingList(false)
        }
      });
    } catch (error) {
      console.log(error);
      setLoadUpcomingList(false)
    }
  }

  const handleSessionDetails = (val) => {
    navigate(`../${AdminRoute?.Common?.SessionDetails}?id=${val._id}`);
  }

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className='toggleIcon'>
        <div className="container-fluid">
          <div className="row list">
            <div className="nav-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className='w-100 h-head'>
                  <h2 className='heading  mt-1 mb-1'>My Sessions</h2>
                  <Button type="submit" className="create-new_notes button_common notes-btn transparent-btn"><NavLink to={`${AdminRoute?.Common?.Appointment_scheduler}/?key=true`}>Book a Sessions </NavLink></Button>
                </div>
                <Header />
              </nav>
            </div>
            <div className="col-12">
              <Button type="submit" className="create-new_notes button_common notes-btn transparent-btn d-block d-md-none my-2 me-0"><NavLink to={`${AdminRoute?.Common?.Appointment_scheduler}/?key=true`}>Book a Sessions </NavLink></Button>
            </div>
            <div className="col-md-12 mb-2 list-item v-height sessions-v-height">
              <div className="sessions-tabs">
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className="mb-3 ps-0"
                >
                  <Tab eventKey="home" title="Upcoming Sessions">
                    <p className='row sessions-text ps-0'>Here is a detail list of all your upcoming and past sessions.</p>
                    {/* <div className='d-flex justify-content-around'><span className='badge-text'>June 2023</span>  </div>*/}
                    <div className="d-flex justify-content-around">
                      <ul className="auto-grid px-0 mx-0 my-3 w-100">
                        {
                          loadUpcomingList ? <div className="container_loader"></div> :
                            upcomingSessionList?.length === 0 ? <span>Data not found</span> :
                              upcomingSessionList?.map((val, i) => {
                                return (
                                  <li key={i}>
                                    <div className="slot-container inner-div">
                                      <div className="slot-time">
                                        <div className="slot">{val?.timestart}</div>
                                        <div className='text-div-sessions'>
                                          <div className="slot-div-date">{moment(val?.date).format("DD-MM-YYYY")}</div>
                                          <div className="slot-div-name">{val?.theropis[0]?.fullname}</div>
                                        </div>
                                        <div className="clock">
                                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                            <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                          </svg>
                                        </div>
                                      </div>

                                    </div>
                                  </li>
                                )
                              })
                        }
                      </ul>
                    </div>

                  </Tab>
                  <Tab eventKey="profile" title="Sessions Summary">


                    <div className="d-flex justify-content-around">
                      <ul className="auto-grid">
                        {
                          loadSumaryList ? <div className="container_loader"></div> :
                            sumarySessionList?.length === 0 ? <span>Data not found</span> :
                              sumarySessionList?.map((val) => {
                                return (
                                  <li key={val?._id}>
                                    <div className="slot-container inner-div cursor_pointer" onClick={() => handleSessionDetails(val)}>
                                      <div className="slot-time">
                                        <div className="slot">{val?.timestart}</div>
                                        <div className='text-div-sessions'>
                                          <div className="slot-div-date">{moment(val?.date).format("DD-MM-YYYY")}</div>
                                          <div className="slot-div-name">{val?.theropis[0]?.fullname}</div>
                                        </div>
                                        <div className="clock py-4">
                                          <i className="fa fa-chevron-right"></i>
                                        </div>
                                      </div>

                                    </div>
                                  </li>
                                )
                              })
                        }
                      </ul>
                    </div>
                    {/*
                   <div className='p-session'>
                      <div className='d-flex justify-content-around'><span className='badge-text'>June 2023</span>  </div>

                      <div className="d-flex justify-content-around">
                        <ul className="auto-grid">
                          <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>
                          <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  */}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div >






  );
}

export default MySession;

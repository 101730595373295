import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import GoogleLogin from "./../Firebase/firebase";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const initialValues = { InputType: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const [error, setError] = useState("");
  const [ApiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const mobileRegex = /^\d+$/;

  const validate = (values) => {
    const errors = {};

    if (!values?.InputType) {
      errors.InputType = "EmailId or Mobile no is Required!";
    }
    if (mobileRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Mobile Number!";
    }
    if (emailRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Email ID!";
    }
    if (
      mobileRegex.test(values?.InputType) &&
      emailRegex.test(values?.InputType)
    ) {
      errors.InputType = "Invalid Input!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (emailRegex.test(formValues?.InputType)) {
        setLoading(true);
        try {
          API.Auth.LoginAPIEmail({
            data: {
              email: formValues?.InputType,
              role: "Client",
              formname: "Login",
            },
          }).then((response) => {
            localStorage.setItem("EmailId", formValues?.InputType);
            if (
              response?.data?.status === 1 &&
              response?.data?.message === "The OTP has been sent successfully."
            ) {
              setApiError(response?.data?.message);
              navigate(
                `../${AdminRoute?.Auth?.verifyOtpMail}/1?email=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "User not registered."
            ) {
              navigate(
                `../${AdminRoute?.Auth?.Register}?email=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
            }  else if (
              response?.data?.status === 0 &&
              response?.data?.message === "Please complete the registration."
            ) {
              // ================================================================
              // handleResendOtpEmail(response?.data?.data[0]?.email);    // Testing Remaing because of otp not sent
              // ====================================================================
              localStorage.setItem("Mobileno", response?.data?.data?.phone);

              if (response?.data?.data?.is_verifiedp === true) {
                handleResendOtpEmail(response?.data?.data?.email);
                navigate(
                  `../${
                    AdminRoute?.Auth?.verifyOtpMail
                  }/2?email=${JSON.stringify(formValues?.InputType)}`
                );
              } else {
                handleResendOtp(response?.data?.data?.phone);
                navigate(
                  `../${
                    AdminRoute?.Auth?.VerifyOtp
                  }/2?mobile_no=${JSON.stringify(response?.data?.data?.phone)}`
                );
              }
              setApiError(response?.data?.message);
            }
            // setApiError(response?.data?.text);
            setLoading(false);
          });
        } catch (error) {
          setApiError(error.message);
          console.log(error);
          setLoading(false);
        }
      }

      if (mobileRegex.test(formValues?.InputType)) {
        setLoading(true);
        try {
          API.Auth.LoginAPIMobile({
            data: {
              phone: formValues?.InputType,
              role: "Client",
              formname: "Login",
            },
          }).then((response) => {
            localStorage.setItem("Mobileno", formValues?.InputType);
            if (
              response?.data?.status === 1 &&
              response?.data?.message === "The OTP has been sent successfully."
            ) {
              navigate(
                `../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "User not registered."
            ) {
              navigate(
                `../${AdminRoute?.Auth?.Register}?mobile_no=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message ===
                "Please wait for some time, unable to send message."
            ) {
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "Please complete registration..."
            ) {
              // ====================================================================
              // handleResendOtp(response?.data?.data?.phone);   // Testing Remaing because of otp not sent
              // ==========================================================================
              localStorage.setItem("EmailId", response?.data?.data[0]?.email);
              if (response?.data?.data[0]?.is_verifiedp === true) {
                handleResendOtpEmail(response?.data?.data[0]?.email);
                navigate(
                  `../${
                    AdminRoute?.Auth?.verifyOtpMail
                  }/2?email=${JSON.stringify(response?.data?.data[0]?.email)}`
                );
              } else {
                handleResendOtp(response?.data?.data[0]?.phone);
                navigate(
                  `../${
                    AdminRoute?.Auth?.VerifyOtp
                  }/2?mobile_no=${JSON.stringify(formValues?.InputType)}`
                );
              }

              // setApiError(response?.data?.message);
            }
            // setApiError(response?.data?.message);
            setLoading(false);
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    }
  }, [formErrors]);

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {

  //     if (emailRegex.test(formValues?.InputType)) {
  //       API.Auth.LoginAPIEmail({
  //         data: {
  //           email: formValues?.InputType,
  //           role: "Client",
  //           formname: "Login",
  //         },
  //       })
  //         .then((response) => {
  //           if (response?.data?.status === 1) {
  //             navigate(
  //               `../${AdminRoute?.Auth?.verifyOtpMail}/1?email=${JSON.stringify(
  //                 formValues?.InputType
  //               )}`
  //             );
  //             // toast.success(response?.data?.message);
  //             setError("");
  //           } else {
  //             if (response?.data?.message === "User not registered.") {
  //               navigate(
  //                 `../${AdminRoute?.Auth?.Register}?email=${JSON.stringify(
  //                   formValues?.InputType
  //                 )}`
  //               );
  //             } else if (!response?.data?.verifiedboth) {
  //               handleResendOtp(response?.data?.data?.phone);
  //               navigate(
  //                 `../${
  //                   AdminRoute?.Auth?.verifyOtpMail
  //                 }/2?email=${JSON.stringify(
  //                   response?.data?.data?.email
  //                 )}&mobile_no=${JSON.stringify(response?.data?.data?.phone)}`
  //               );
  //             }
  //             // toast.error(response?.data?.message);
  //             setError(response?.data?.message);
  //           }
  //           setError(response?.data?.message);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //       setIsSubmit(false);
  //     }

  //     if (mobileRegex.test(formValues?.InputType)) {
  //       API.Auth.LoginAPIMobile({
  //         data: {
  //           phone: formValues?.InputType,
  //           role: "Client",
  //           formname: "Login",
  //         },
  //       })
  //         .then((response) => {
  //           if (response?.data?.status === 1) {
  //             navigate(
  //               `../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(
  //                 formValues?.InputType
  //               )}`
  //             );
  //             // toast.success(response?.data?.message);
  //             setError(response?.data?.message);
  //           } else {
  //             if (response?.data?.message === "User not registered.") {
  //               navigate(
  //                 `../${AdminRoute?.Auth?.Register}?mobile_no=${JSON.stringify(
  //                   formValues?.InputType
  //                 )}`
  //               );
  //             } else if (!response?.data?.verifiedboth) {
  //               setError(response?.data?.message);
  //               handleResendOtpEmail(
  //                 response?.data?.message ===
  //                   "Please wait for some time not able to send message..."
  //                   ? ""
  //                   : response?.data?.data[0]?.email
  //               );
  //               navigate(
  //                 `../${AdminRoute?.Auth?.VerifyOtp}/2?email=${JSON.stringify(
  //                   response?.data?.data[0]?.email
  //                 )}&mobile_no=${JSON.stringify(
  //                   response?.data?.data[0]?.phone
  //                 )}`
  //               );
  //             }
  //             toast.error(response?.data?.message);
  //             setError(response?.data?.message);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //       setIsSubmit(false);
  //     }
  //   }
  // }, [formErrors]);

  const handleSubmitMobile = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleResendOtp = (mobile) => {
    try {
      API.Auth.LoginAPIMobile({
        data: {
          phone: mobile,
          role: "Client",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          // toast.success(response?.data?.message);
          setApiError("");
        } else {
          // toast.error(response?.data?.message);
          setApiError(response?.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOtpEmail = (email) => {
    try {
      API.Auth.LoginAPIEmail({
        data: {
          email: email,
          role: "Client",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          // toast.success(response?.data?.message);
          setApiError("");
        } else {
          // toast.error(response?.data?.message);
          setApiError(response?.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container login-page">
      <div className="row main-container">
        <div className="col-md-6 col-sm-12 left-text">
          <div className="logo-div">
            <NavLink to="/">
              <img src="/images/logo.png" className="logo" alt="logo" />{" "}
            </NavLink>
          </div>
          <h1 className="heading">Take Charge of Your Mental Well-Being!</h1>
          <p className="mb-2 fw-bold">Are you ready to:</p>
          <ul className="login-list">
            <li>Reduce stress and burnout</li>
            <li>Feel happier and more productive</li>
            <li>Boost your mood and energy levels</li>
          </ul>
          <p className="subheading w-100">
            If yes, you’re in the right place!{" "}
          </p>
        </div>
        {/* col-md-6 */}

        <div className="col-md-6 col-sm-12">
          <div className="register">
            <form>
              <h2 className="heading">Login</h2>

              <div>
                <label>
                  <span className="text-danger fw-bold">*</span> Enter
                  registered E-Mail ID/ Mobile No
                </label>
                <input
                  type="text"
                  name="InputType"
                  placeholder="Enter registered E-Mail ID/ Mobile No"
                  // defaultValue={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="text-danger">{formErrors?.InputType}</p>
              <p className="text-danger">{ApiError}</p>

              {/* {error && <p className="text-danger">{error}</p>} */}

              <button
                type="submit"
                className="button_common w-100 mt-3 mb-3"
                disabled={isSubmit}
                onClick={(e) => handleSubmitMobile(e)}
              >
                Verify via OTP
              </button>
              {loading ? <div className="loader_orange"></div> : <></>}

              {/* <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck1" />
                  <label className="custom-control-label" htmlFor="customCheck1">Stay logged in </label>
                </div>
              </div> */}
              {/* 
              <h6 className="or-text text-center">OR</h6>
              <div className="stage">
                <div>
                  <GoogleLogin />
                </div>
              </div>
              <p className="account-text">Don&apos;t have an account?</p> */}

              <NavLink
                className="register-text"
                to={AdminRoute?.Auth?.Register}
              >
                Let&apos;s Register{" "}
              </NavLink>
            </form>
          </div>
        </div>
        {/* col-md-6 */}
      </div>
    </div>
  );
};
export default Login;

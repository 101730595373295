import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import moment from "moment";
import baseApi from '../../Api/config';
import { toast } from "react-toastify";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SuccessPop from '../Common/modal/SuccessPop';
import { checkButtonRange } from '../Common/Functions';

const Join_Group = () => {
  const navigate = useNavigate();
  const [FocussedData, setFocussedData] = useState([]);
  const [SupportData, setSupportData] = useState([]);
  const [showPopup, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focussedLoading, setfocussedLoading] = useState(false);
  const [supportLoading, setSupportLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [Searchtext, setSearchText] = useState("");
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  useEffect(() => {
    hetSupportData()
    getFocussedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hetSupportData = (info, text) => {
    setSupportLoading(true)
    API.Auth.Support_APIAll({
      support: "support",
      serchKey: text,
      serchBy: selectedOption
    }).then((response) => {
      if (response.data.status === 1) {
        if (info === "Support") {
          setShowPopUp(true)
          setTimeout(() => {
            setShowPopUp(false)
          }, 2000)
        }
        setSupportLoading(false)
        setSupportData(response?.data?.data_all);
      }
    }).catch((error) => {
      setSupportLoading(false)
      console.log(error);
    });
  }

  const getFocussedData = (info, text) => {
    setfocussedLoading(true)
    API.Auth.Support_APIAll({
      support: "focused",
      serchKey: text,
      serchBy: selectedOption
    }).then((response) => {
      if (response.data.status === 1) {
        if (info === "Focussed") {
          setShowPopUp(true)
          setTimeout(() => {
            setShowPopUp(false)
          }, 2000)
        }
        setfocussedLoading(false)
        setFocussedData(response?.data?.data_all);
      }
    }).catch((error) => {
      setfocussedLoading(false)
      console.log(error);
    });
  }


  const handleRegister = (group_id, info) => {
    // setLoading(true)
    API.Groups.JoinGroups({
      data: {
        "userId": UserData?.userdata?._id,
        "groupId": group_id
      }
    }).then((response) => {
      if (response.data.status === 1) {

        setShowPopUp(true)
        setTimeout(() => {
          setShowPopUp(false)
        }, 2000)
        hetSupportData()
        getFocussedData()

        // if (info === "Support") {
        //   hetSupportData(info)
        // }
        // if (info === "Focussed") {
        //   getFocussedData(info)
        // }
        // setLoading(false)
        // toast.success("group joined successfully")
      }
      if (response.data.status === 0) {
        toast.error(response?.data?.message)
      }
    }).catch((error) => {
      // setLoading(false);
      console.log(error);
    });
  }

  // const handleSearchText = (text) => {
  //   hetSupportData("", text)
  //   getFocussedData("", text)
  // }

  useEffect(() => {
    hetSupportData("", Searchtext)
    getFocussedData("", Searchtext)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Searchtext, selectedOption])


  return (
    <>
      {showPopup ? <SuccessPop text="Successfully Joined Group" />
        :
        <div className="dashboard therapist">
          <div className="row d-flex justify-content-center text-center inner my-0 join-group-wrap">
            <div className="top-bar-nav">
              <Navbar className='top-bar'>
                <span className="backbtn" onClick={() => navigate(-1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                  </svg>
                </span>
                <Nav className="mr-auto align-items-start align-items-md-center">
                  <div className='search-box d-block d-md-flex me-3 me-md-0'>
                    <select
                      className="form-control form-select me-2 px-4 search-select w-100 mb-2 mb-md-0"
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                    >
                      <option selected value="">Search By</option>
                      <option value="Therapist">Therapist</option>
                      <option value="Group">Group</option>
                    </select>
                    <Form.Group className="w-100 position-relative select-therapist-search">
                      <i className="fa fa-search position-absolute"></i>
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="search-input"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </Form.Group>
                  </div>

                  <span className="logo-div mt-0">
                    <NavLink to="/"><img src='/images/logo.png' className="logo my-0 my-md-2" alt="logo" /> </NavLink>
                  </span>
                </Nav>
              </Navbar>
            </div>
            <div className="col-md-12">
              <h2 className='heading mb-2 mt-4'>Empower Your Well-Being in Our Supportive Community.</h2>
              <h4 className='secheading mb-2'>Explore Our Specialty Groups. </h4>
            </div>

            <div className="col-md-12 focussed">
              <p className="mb-1 focussed-heading mt-4 mb-3">Focussed Groups</p>

              <Carousel responsive={responsive}>
                {focussedLoading ?
                  <div className='container_loader'></div>
                  : FocussedData?.length === 0 ?
                    <div className="alert alert-warning digital-notes-alert" role="alert">
                      Data Not Found
                    </div>
                    :
                    FocussedData && FocussedData?.map((slide) => {
                      return (
                        <div className='row h-100'>
                          <div className="card-body mx-4" key={slide?._id}>
                            <div className="focussed-div d-flex justify-content-center align-items-center overflow-hidden">
                              <span className='focussed-text'>Focussed</span>
                              <img
                                className="d-block w-100"
                                src={slide?.logo ? `${baseApi.baseurl}/images/${slide?.logo}` : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`}
                                alt={slide?.caption}
                              />
                            </div>
                            <div className="focussed-div-text">
                              <h3 className="card-title">{slide?.title}</h3>
                              <p className="card-text mb-0 fw-bold">{slide?.name}</p>
                              <p className="card-text my-2">Members: {slide?.members}</p>
                              <p className="endDate mb-1 fw-bold">Therapist Name : {slide?.therapist[0]?.fullname}</p>
                              <p className="endDate mb-1 fw-bold">Experties : {slide?.therapist[0]?.experties}</p>
                              <p className="startDate mt-1 mb-1">Start Date:  {moment(slide?.startdate).format("DD/MM/YYYY")}</p>
                              <p className="endDate mb-1">End Date : {moment(slide?.enddate).format("DD/MM/YYYY")}</p>
                              
                              <Button
                              className="transparent-btn mt-2 mb-2"
                              onClick={() => handleRegister(slide._id, "Focussed")}
                              disabled={(!checkButtonRange(slide?.startdate, slide?.enddate) || slide?.usergroup.length)}
                            >
                              
                              {(!checkButtonRange(slide?.startdate, slide?.enddate) || slide?.usergroup.length)? "Registred":"Register"
                              }
                            </Button>
                             
                            </div>
                          </div>
                        </div>
                      )
                    })}
              </Carousel>
            </div>

            <div className="col-md-12 focussed">
              <p className="mt-4 mb-1 focussed-heading mb-3">Support Groups</p>

              <Carousel responsive={responsive}>
                {supportLoading ?
                  <div className='container_loader'></div>
                  : SupportData?.length === 0 ?
                    <div className="alert alert-warning digital-notes-alert" role="alert">
                      Data Not Found
                    </div>
                    : SupportData && SupportData?.map((slide) => {
                      return (
                        <div className='row'>
                          <div className="card-body mx-4" key="">
                            <div className="support-div d-flex justify-content-center align-items-center overflow-hidden">
                              <span className='support-text'>Support</span>
                              <img
                                className="d-block w-100"
                                src={slide.logo ? `${baseApi.baseurl}/images/${slide.logo}` : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`}
                                alt={slide.caption}
                              />
                            </div>

                            <div className="support-div-text">
                              <h3 className="card-title">{slide?.title}</h3>
                              <p className="card-text mb-0 fw-bold">{slide?.name}</p>
                              <p className="endDate mb-1 fw-bold">Therapist Name : {slide?.therapist[0]?.fullname}</p>
                              <p className="endDate mb-1 fw-bold">Experties : {slide?.therapist[0]?.experties}</p>
                              {/* <p className="card-text my-3">Members: {slide.members}</p>*/}
                              {/* <p className="startDate mt-1 mb-1">Start Date:  {moment(slide?.startdate).format("DD/MM/YYYY")}</p>
                      <p className="endDate mb-1">End Date : {moment(slide?.enddate).format("DD/MM/YYYY")}</p> */}
                              <Button
                                className="transparent-btn mt-2 mb-2"
                                onClick={() => handleRegister(slide._id, "Support")}
                                disabled={slide?.usergroup?.length}
                              >
                                {slide?.usergroup?.length?"Registred":"Register"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
              </Carousel>
              <div className='col-md-12 d-flex skip'>
                {/* <div className='mt-4 mb-4 skip-text'> <NavLink to={`../${AdminRoute.Common.Dashboard}`}>Skip </NavLink></div> */}
                <div>
                  <Button type="submit" className="button_common mt-4 ml-4 next-btn">
                    <NavLink to={AdminRoute?.Common?.Dashboard}>Next </NavLink>
                  </Button>
                </div>
              </div>
            </div>
          </div>

        </div >
      }
    </>
  )
}
export default Join_Group;
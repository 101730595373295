// App.js
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import React, { useState, useEffect } from "react";
import API from "../../Api/Api";
import moment from "moment";
import commoan from "../../environment/Config";

const Playground = () => {
  const [Mental_HealthList, setMental_HealthList] = useState([]);
  const [Videos, setVideos] = useState([]);
  const [Assessment_List, setAssessment_List] = useState([]);
  const [loadingMental_HealthList, setLoadingMental_HealthList] =
    useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingAssessment_Lists, setLoadingAssessment_List] = useState(false);

  //get all data
  useEffect(() => {
    getMntal_HealthList();
    get_VideoList();
    get_AssesmentList();
  }, []);

  //api call for Mental Health  list
  const getMntal_HealthList = () => {
    setLoadingMental_HealthList(true);
    try {
      API.Auth.Mental_HealthAPI({
        health: "health",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setMental_HealthList(response?.data?.data_all);
        }
        setLoadingMental_HealthList(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingMental_HealthList(false);
    }
  };

  //api call for videos

  //api call for Mental Health  list
  const get_VideoList = () => {
    setLoadingVideos(true);
    try {
      API.Auth.Videos_API({
        kind: "kind",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setVideos(response?.data?.data_all);
        }
        setLoadingVideos(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingVideos(false);
    }
  };

  //api  call for self assessment

  const get_AssesmentList = () => {
    setLoadingAssessment_List(true);
    try {
      API.Auth.SelfAsses_API({
        selfAsses: "selfAsses",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setAssessment_List(response?.data?.data_all);
        }
        setLoadingAssessment_List(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingAssessment_List(false);
    }
  };

  const get_video = (e, video) => {
    const response = {
      file_path: video,
    };
    window.open(response?.file_path);
  };

  useEffect(() => {
    console.log(Mental_HealthList);
  }, [Mental_HealthList]);

  //jsx
  return (
    <div className="d-flex h-dashboard playground">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list dashboard therapist">
            <div className="nav-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading p-0">My Playground</h2>
                </div>
                <Header />
              </nav>
            </div>
            <p>Here is a detail list of all your upcoming and past sessions.</p>

            <div className="col-md-12">
              <h4 className="secheading mb-2">Mental Health Resources</h4>
              <div className="wrapper-play">
                {loadingMental_HealthList ? (
                  <div className="container_loader"></div>
                ) : Mental_HealthList.length === 0 ? (
                  <span>Data not found</span>
                ) : (
                  Mental_HealthList?.map((val) => (
                    <div className="box" key={val?._id}>
                      <div className="card-body">
                        <div className="playground-img-div mb-2">
                          <img
                            src={
                              `${commoan.baseUrl}` +
                              `images/` +
                              val?.backgroundimagename
                            }
                            alt={
                              `${commoan.baseUrl}` +
                              `images/` +
                              val?.backgroundimagename
                            }
                            height={50}
                            className="img-fluid select-note-theme-img"
                          />
                        </div>
                        <div className="support-div-text px-3">
                          <h3 className="card-title mb-0">{val?.header}</h3>
                          <p className="card-text mb-0 mt-0">
                            Author: {val?.header}
                          </p>
                          <p className="card-text grey-text blog mb-0">
                            Blog: {val?.header}
                          </p>
                          <p className="card-text published-date mb-2">
                            Published Date:{" "}
                            {moment(val?.createdAt).format("LL")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            {/* //videos */}
            <div className="col-md-12">
              <h4 className="secheading mb-2">Videos</h4>
              <div className="wrapper-play videos overflow-hidden">
                {loadingVideos ? (
                  <div className="container_loader"></div>
                ) : Videos.length === 0 ? (
                  <span>Data not found</span>
                ) : (
                  Videos?.map((val) => (
                    <div className="box" key={val?._id}>
                      <div className="card-body">
                        <div
                          className="playground-img-div mb-2 pb-0 cursor_pointer"
                          onClick={(e) => get_video(e, val?.url)}
                        >
                          <img
                            src={
                              `${commoan.baseUrl}` +
                              `images/` +
                              val?.backgroundimagename
                            }
                            alt={
                              `${commoan.baseUrl}` +
                              `images/` +
                              val?.backgroundimagename
                            }
                            className="img-fluid select-note-theme-img"
                          />
                        </div>
                        <div className="support-div-text px-3">
                          <h3 className="card-title mb-0">{val?.header}</h3>
                          <p className="card-text mb-0 mt-0">Creator: </p>
                          <p className="card-text grey-text blog mb-0">Video</p>
                          <p className="card-text text-muted published-date mb-2">
                            Published Date:{" "}
                            {moment(val?.createdAt).format("LL")}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            {/* self assessment */}
            <div className="col-md-12">
              <h4 className="secheading mb-2">Self Assesment</h4>
              <div className="wrapper-play ">
                {loadingAssessment_Lists ? (
                  <div className="container_loader"></div>
                ) : Assessment_List.length === 0 ? (
                  <span>Data not found</span>
                ) : (
                  Assessment_List?.map((val) => (
                    <div className="box" key={val._id}>
                      <div className="card-body">
                        <div className="playground-img-div mb-2">
                          {" "}
                          <img
                            src={
                              `${commoan.baseUrl}` +
                              `images/` +
                              val?.backgroundimagename
                            }
                            alt={
                              `${commoan.baseUrl}` +
                              `images/` +
                              val?.backgroundimagename
                            }
                            className="img-fluid select-note-theme-img"
                          />
                        </div>
                        <div className="support-div-text">
                          <h3 className="card-title mb-0">{val?.header}</h3>
                          <p className="card-text mb-0 mt-0">
                            Author: Jiteshwar Naidu
                          </p>
                          <p className="card-text grey-text blog mb-0">Blog</p>
                          <p className="card-text text-muted published-date mb-2">
                            Published Date: 01 Sept, 2023
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Playground;

import React from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { useNavigate, NavLink } from "react-router-dom";
import AdminRoute from "../Route/RouteDetails";
import {
  get15MinutesBefore,
  get1HourFuture,
  combineDateTime,
} from "./Common/Functions";
import API from "../Api/Api";
import { toast } from "react-toastify";

const JoinSession = ({ currentSession, joinSessionBtn, therapistData }) => {
  const navigate = useNavigate();
  const date =
    currentSession?.date && currentSession?.timestart
      ? combineDateTime(currentSession.date, currentSession.timestart)
      : new Date(); // shedual time zone
  // const tempDate = '2024-02-25T01:30:00.000Z'
  const currdate = new Date(); //current time

  const beforeminute = date ? new Date(get15MinutesBefore(date)) : new Date(); // get 15 min before time
  // console.log(currdate > beforeminute) // true so sesstion btn show

  const endDate = date && new Date(get1HourFuture(date));
  // const startDateResutl = (currdate > beforeminute) // true show btn
  // console.log(currentSession)

  const startDateResult = currdate > beforeminute; // true so sesstion btn show
  const endDateResult = currdate < endDate; // true seesion btn show

  const renderActionButton = () => {
    API.Session.JoinMeeting({
      data: {
        role: "Client",
        meetingId: currentSession?.mettingname,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          navigate(
            `../${AdminRoute?.Common?.VideoCall}?id=${btoa(
              currentSession?.meetingtoken
            )}&name=${currentSession?.mettingname}`
          );
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // config.selectedProduct = "rtc";
  return (
    <div className="col-lg-12 col-xl-6 mb-2 col-xxl-3 list-item">
      <div className="dashboard-inner p-3">
        <div className="row h-100">
          <div className="col-md-12 position-relative">
            <div className="time-text-right">
              <div className="dashboard-text-div">
                <h3 className="secheading mb-3">Upcoming Session</h3>
                <p className="my-3">
                  Attend all sessions for speedier recovery.
                </p>
                <div className="upcoming-session-box d-flex justify-content-between align-items-center px-3 py-2 mb-3">
                  {currentSession?.date ? (
                    <>
                      <div className="date-month h-4">
                        <p className="h-4">
                          {/* {currentSession &&
                            moment(currentSession?.date).format("DD")} */}
                          {currentSession &&
                            currentSession?.date?.split("T")[0]?.split("-")[2]}
                        </p>
                        <p className="fw-bold">{currentSession?.month}</p>
                      </div>
                      <div className="day-time">
                        <p className="mt-0 fw-bold">{currentSession?.day}</p>
                        <p className="mb-0">{currentSession?.timestart}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="hide-session">
                        <p className="my-2">No Sesson Available</p>
                      </div>
                    </>
                  )}
                </div>
                <div className="text-end">
                  {currentSession?._id && (
                    <p
                      className="me-2 reschedule text-decoration-underline cursor_pointer text-end mb-3"
                      onClick={() =>
                        navigate(
                          `../${AdminRoute?.Common?.Appointment_scheduler}/?id=${currentSession?._id}`
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        className="bi bi-clock"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                      </svg>
                      <span className="ms-1">Reschedule</span>
                    </p>
                  )}
                  {currentSession?.date && joinSessionBtn ? (
                    <Button
                      className="button_common m-0 float-end position-absolute bottom-0 dash-button"
                      onClick={() => renderActionButton()}
                    >
                      Join Session
                    </Button>
                  ) : (
                    <>
                      {therapistData?.fullname ? (
                        <Button className="button_common m-0 float-end position-absolute bottom-0 dash-button">
                          <NavLink
                            to={`${AdminRoute?.Common?.Appointment_scheduler}/?key=true`}
                          >
                            Book a Sessions
                          </NavLink>
                        </Button>
                      ) : (
                        <Button className="button_common m-0 float-end position-absolute bottom-0 dash-button">
                          <NavLink
                            to={`${AdminRoute?.Common?.Therapist}/?key=true`}
                          >
                            Book a Sessions
                          </NavLink>
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinSession;

// Header.js
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Dropdown from "react-bootstrap/Dropdown";
import Sidebar from "../Common/Sidebar";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import AdminRoute from "../../Route/RouteDetails";
import { NavLink } from "react-router-dom";
import API from "../../Api/Api";
import { formatDateTime } from "./Functions";
import { socket } from "../../socket";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getInitialsLetters } from '../Common/Functions';

function Header() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState([]);
  const [room] = useState("notification");
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isOpen, setIsOpen] = useState(false);


  // useEffect(() => {
  //   if (!socket || !room) return;

  //   const handleData = (data) => {
  //     setNotification((list) => [data, ...list]);
  //   };

  //   socket.on(room, handleData);

  //   return () => {
  //     socket.off(room, handleData); // Use the same handler to properly remove it
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socket, room]);

  useEffect(() => {
    API.NotificationApis.getAllnotificationApi()
      .then((response) => {
        if (response.data.status === 1) {
          setNotification(response.data.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line no-use-before-define
  }, [isOpen]);


  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };

  return (
    <div className="header">
      <div className="toggle-menu">
        <Dropdown className="d-inline" autoClose={false}>
          <Dropdown.Toggle id="dropdown-autoclose-false"></Dropdown.Toggle>

          <Button variant="primary" onClick={handleShow}>
            <Button className="btn mb-logo" id="collapseToggle">
              <span id="toggleIcon" className="navbar-toggler-icon" />
            </Button>
          </Button>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <Sidebar />
            </Offcanvas.Body>
          </Offcanvas>

          <Dropdown.Menu></Dropdown.Menu>
        </Dropdown>
      </div>
      <ul className="navbar-nav ms-auto mt-lg-0 align-items-center">
        <li className="notification-dropdown">
          <Dropdown onToggle={handleToggle}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="icon-div p-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="icon icon-lg"
                  role="img"
                >
                  <path
                    fill="var(--ci-primary-color, currentColor)"
                    d="M450.27,348.569,406.6,267.945V184c0-83.813-68.187-152-152-152s-152,68.187-152,152v83.945L58.928,348.568A24,24,0,0,0,80.031,384h86.935c-.238,2.636-.367,5.3-.367,8a88,88,0,0,0,176,0c0-2.7-.129-5.364-.367-8h86.935a24,24,0,0,0,21.1-35.431ZM310.6,392a56,56,0,1,1-111.419-8H310.018A56.14,56.14,0,0,1,310.6,392ZM93.462,352,134.6,276.055V184a120,120,0,0,1,240,0v92.055L415.736,352Z"
                    className="ci-primary"
                  ></path>
                </svg>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {notification &&
                notification.map(
                  ({ title, description, createdAt, type, receiver_id }, i) => {
                    return (
                      <React.Fragment key={i}>
                        {type === "all" ? (
                          <Dropdown.Item>
                            <div className="preview-item-joined flex-grow py-1">
                              <div className="notification-wrapper ps-2">
                                <p className="preview-subject ellipsis font-weight-medium text-dark">
                                  {title}
                                </p>
                                <p className="fw-light small-text mb-0 grey-text">
                                  {description}
                                </p>
                                <p className="date grey-text">
                                  {formatDateTime(createdAt)}
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ) : receiver_id === UserData.userdata._id ? (
                          <Dropdown.Item>
                            <div className="preview-item-joined flex-grow py-1">
                              <div className="notification-wrapper ps-2">
                                <p className="preview-subject ellipsis font-weight-medium text-dark">
                                  {title}
                                </p>
                                <p className="fw-light small-text mb-0 grey-text">
                                  {description}
                                </p>
                                <p className="date grey-text">
                                  {formatDateTime(createdAt)}
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ) : null}
                      </React.Fragment>
                    );
                  }
                )}
            </Dropdown.Menu>
          </Dropdown>
        </li>

        <li className="account-dropdown">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="User rounded">
                {UserData?.userdata?.image ?

                  <img className="imgronded" src={UserData?.userdata?.image} alt="profile"></img>
                  :
                  <p className="imgronded ">{getInitialsLetters(UserData?.userdata?.name, UserData?.userdata?.last_name)}</p>
                }
                {/* <img 
                src="images/user-white.png" 
                className="User" 
                alt="icon" /> */}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <NavLink to={AdminRoute.Common.Profile}>My account</NavLink>
              <br></br>
              <NavLink to={AdminRoute.Common.Payment}>Payments</NavLink>
              <br></br>
              <NavLink to={AdminRoute.Common.Settings}>Settings</NavLink>
              <br></br>
              {/* <Dropdown.Item href="/payment"></Dropdown.Item>
              <Dropdown.Item href="/settings"> </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  );
}

export default Header;
